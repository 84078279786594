import { FC, ReactNode, useEffect, useState } from "react";
import { Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useActions } from "../../../hooks/useActions";
import getChoiceTitle from "../../../helpers/getChoiceTitle";
import { sortByOrder } from "../../../helpers/sortByOrder";

interface DraggableRowProps {
  id: string | number;
  children: ReactNode;
}

const DraggableRow: FC<DraggableRowProps> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </tr>
  );
};

type RangingComponentProps = {
  setIsAnswerMoved: (isAnswerMoved: boolean) => void;
};

const RangingComponent: FC<RangingComponentProps> = ({ setIsAnswerMoved }) => {
  const question = useSelector((state: RootState) => state.questions.question);
  const answerChoices = question?.answerTemplate?.answerChoices ?? [];
  const mixedAnswers = question?.answerTemplate?.mixedAnswers;
  const hideLineOrder = question?.answerTemplate?.hideLineOrder ?? false;
  const { setUserAnswer } = useActions();
  const [sortedOptions, setSortedOptions] = useState<any[]>([]);

  setIsAnswerMoved(false);

  useEffect(() => {
    let initialOptions = answerChoices
      .filter((ac) => ac)
      .sort(sortByOrder)
      .map(getChoiceTitle)
      .filter((it) => !!it.choiceId)
      .map((option, index) => ({
        ...option,
        order: index + 1,
      }));

    if (mixedAnswers) {
      for (let i = initialOptions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [initialOptions[i], initialOptions[j]] = [initialOptions[j], initialOptions[i]];
      }

      initialOptions = initialOptions.map((option, index) => ({
        ...option,
        order: index + 1,
      }));
    }

    setSortedOptions(initialOptions);

    const initialChoiceObjects = initialOptions.map((option) =>
      JSON.stringify({
        value: option.choiceId,
        order: option.order,
      }),
    );

    setUserAnswer({ answerValue: "", csiAnswers: [], choices: initialChoiceObjects });
  }, [answerChoices, mixedAnswers]);

  const handleChange = () => {
    setIsAnswerMoved(true);
  };

  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = sortedOptions.findIndex((item) => item.choiceId === active.id);
      const newIndex = sortedOptions.findIndex((item) => item.choiceId === over.id);
      const newOptions = arrayMove(sortedOptions, oldIndex, newIndex);

      const reorderedOptions = newOptions.map((option, index) => ({
        ...option,
        order: index + 1,
      }));

      setSortedOptions(reorderedOptions);
      handleChange();

      const choiceObjects = reorderedOptions.map((option) =>
        JSON.stringify({
          value: option.choiceId,
          order: option.order,
        }),
      );

      setUserAnswer({ answerValue: "", csiAnswers: [], choices: choiceObjects });
    }
  };

  const columns = [
    {
      dataIndex: "key",
      width: 50,
      render: (_: any, __: any, index: number) => {
        return hideLineOrder ? null : index + 1;
      },
    },
    {
      dataIndex: "label",
      render: (_: any, record: { label: string }) => (
        <span
          className={question?.surveyDecor?.textFont ? "disable-global-font" : ""}
          style={{ position: "relative", left: 5, fontFamily: question?.surveyDecor?.textFont }}
        >
          {record.label}
        </span>
      ),
    },
    {
      dataIndex: "sort",
      width: 50,
      render: () => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />,
    },
  ];

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={sortedOptions.map((item) => item.choiceId)}>
        <Table
          pagination={false}
          dataSource={sortedOptions.map((option, index) => ({
            key: option.choiceId,
            label: option.label,
            index: index + 1,
          }))}
          columns={columns}
          rowKey="key"
          components={{
            body: {
              row: (props: Record<string, any>) => (
                <DraggableRow children={undefined} {...props} id={props["data-row-key"]} />
              ),
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

export default RangingComponent;
