import React, { FC, useCallback, useEffect, useState } from "react";
import { HeartFilled, StarFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { NpsIconEnum } from "../../../types/npsInfoParams";
import { smileIcons10, smileIcons5, smileIcons6 } from "../../../types/smileyIcons";

type Props = {
  readonly iconType?: NpsIconEnum;
  readonly maxValue: 5 | 6 | 10;
  readonly onChange: (value?: string) => void;
};

const Rating: FC<Props> = ({ iconType, maxValue, onChange }) => {
  const [currentValue, setCurrentValue] = useState<number | undefined>();
  const qId = useSelector((state: RootState) => state.questions.question?.questionId);

  const getSmileIcon = useCallback(
    (index: number) => {
      let icons;
      switch (maxValue) {
        case 5:
          icons = smileIcons5;
          break;
        case 6:
          icons = smileIcons6;
          break;
        case 10:
          icons = smileIcons10;
          break;
        default:
          throw new Error("Invalid maxValue");
      }
      return icons[index];
    },
    [maxValue],
  );

  const isIcon = useCallback(
    (type: NpsIconEnum) => {
      return iconType === type;
    },
    [iconType],
  );

  const handleSmileClick = useCallback(
    (value: number) => {
      const newValue = currentValue === value ? undefined : value;
      setCurrentValue(newValue);
      onChange(newValue ? String(newValue) : undefined);
    },
    [currentValue, onChange],
  );

  useEffect(() => {
    setCurrentValue(undefined);
  }, [qId]);

  const smileSize = maxValue === 5 || maxValue === 6 ? 6 : 4;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      {isIcon(NpsIconEnum.SMILE) &&
        Array.from({ length: maxValue }, (_, index) => {
          const value = index + 1;
          const isSelected = currentValue === value;
          const smileIcon = getSmileIcon(value);

          return (
            <span
              key={value}
              onClick={() => handleSmileClick(value)}
              style={{
                cursor: "pointer",
                fontSize: `${smileSize}vmin`,
                opacity: isSelected ? 1 : 0.5,
                transition: "opacity 0.4s",
              }}
            >
              {smileIcon}
            </span>
          );
        })}
      {!isIcon(NpsIconEnum.SMILE) && (
        <div style={{ display: "flex", flexGrow: 1, justifyContent: "space-between" }}>
          {isIcon(NpsIconEnum.NUMERIC) &&
            Array.from({ length: maxValue }, (_, index) => {
              const value = index + 1;
              const isSelected = currentValue === value;

              return (
                <span
                  key={value}
                  onClick={() => handleSmileClick(value)}
                  style={{
                    cursor: "pointer",
                    fontSize: `${smileSize}vmin`,
                    color: isSelected ? "#fadb14" : "#d9d9d9",
                    margin: "0 5px",
                    opacity: isSelected ? 1 : 0.5,
                    transition: "opacity 0.4s",
                  }}
                >
                  {value}
                </span>
              );
            })}
          {isIcon(NpsIconEnum.HEART) &&
            Array.from({ length: maxValue }, (_, index) => {
              const value = index + 1;
              const isHighlighted = currentValue !== undefined && value <= currentValue;

              return (
                <HeartFilled
                  key={value}
                  onClick={() => handleSmileClick(value)}
                  style={{
                    cursor: "pointer",
                    fontSize: `${smileSize}vmin`,
                    color: isHighlighted ? "#f5222d" : "#d9d9d9",
                    opacity: isHighlighted ? 1 : 0.5,
                    transition: "opacity 0.4s",
                  }}
                />
              );
            })}
          {isIcon(NpsIconEnum.STAR) &&
            Array.from({ length: maxValue }, (_, index) => {
              const value = index + 1;
              const isHighlighted = currentValue !== undefined && value <= currentValue;

              return (
                <StarFilled
                  key={value}
                  onClick={() => handleSmileClick(value)}
                  style={{
                    cursor: "pointer",
                    fontSize: `${smileSize}vmin`,
                    color: isHighlighted ? "#faad14" : "#d9d9d9",
                    opacity: isHighlighted ? 1 : 0.5,
                    transition: "opacity 0.4s",
                  }}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Rating;
